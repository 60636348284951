<template>
  <div :id="stepName" class="step">
    <div class="step-aside">
      <atoms-cta-link v-if="previous" @click="handlePreviousClick">
        <template #start>
          <foundations-ui-icon name="navigation-arrow-left" />
        </template>
        Précédent
      </atoms-cta-link>
    </div>

    <div class="step-main">
      <div class="step-content text">
        <slot name="start" />
      </div>

      <div class="step-inputs">
        <slot />

        <div class="step-submit">
          <atoms-cta-label
            id="button-next"
            variant="attention"
            :class="{ '-disabled': disabled }"
            @click="handleSubmitClick"
          >
            {{ ctaNext }}
          </atoms-cta-label>
        </div>

        <slot name="after-submit" />

        <slot name="legal-notice" />
      </div>
    </div>

    <div class="step-aside"></div>
  </div>
</template>

<script lang="ts">
import { PropType } from 'vue'
import { mapGetters, mapState } from 'vuex'
import { toString } from 'lodash' /* eslint-disable-line import/named */


import { pushCtaClick } from '~/plugins/gtm.client'
import { type Step, type StepSection } from '~/store/steps/state'
import { comparatorRouting, goTo, type Router } from '~/utils/navigation'
import { type ComparatorPage } from '~~/types/navigation'
import { PrismicState } from '~/store/prismic/state'
import { invalid } from 'moment'

export default defineComponent({
  name: 'StepComponent',
  setup: (props) => {
    const router = useRouter()
    const route = router.currentRoute

    return {
      router,
      route,
    }
  },
  props: {
    /**
     * When set to true, prevent navigation to previous page
     */
    preventPrevious: {
      type: Boolean,
      default: false,
    },
    /**
     * When set, used as path for navigation to previous page
     */
    previousPath: {
      type: String,
      default: null,
    },
    section: {
      type: String as PropType<StepSection>,
      default: 'situation',
    },
    stepName: {
      type: String as PropType<Step>,
      required: true,
    },
    v$: {
      type: Object,
      required: true,
    },
    ctaNext: {
      type: String,
      default: 'Suivant',
    },
    invalid: {
      type: Boolean,
      default: null,
    },
  },
  computed: {
    ...(mapGetters({
      stepsData: 'prismic/stepsData',
    }) as {
      stepsData: () => PrismicState['stepsData']
    }),
    disabled() {
      const disabled =
        (this.invalid !== null && this.invalid) ||
        (this.invalid == null && (!this.v$ || !!this.v$.$invalid))

      // send disabled value on bus for sticky bottom submit button
      this.$bus.$emit('form-disabled', disabled)

      return disabled
    },

    next() {
      // if we are on a comparator page, we need to redirect to the next comparator page
      if (this.route.path.includes('/comparateur-')) {
        const path = comparatorRouting(
          this.route.path.replace('/', '') as ComparatorPage,
          this.$store.state.tgp,
          false,
          this.stepsData
        )

        return `/${path}`
      }

      if (this.nextStep()) return `/${toString(this.nextStep())}`

      return undefined
    },
    previous() {
      // if we are on a comparator page, we need to redirect to the previous comparator page
      if (this.route.path.includes('/comparateur-')) {
        const path = comparatorRouting(
          this.route.path.replace('/', '') as ComparatorPage,
          this.$store.state.tgp,
          true,
          this.stepsData
        )

        return `/${path}`
      }

      let previousStep = this.previousPath || this.previousStep()

      if (!previousStep) return

      if (previousStep === 'offres' && this.route.query.token) {
        // when coming from a comparator page or a quote sent by email (i.e. token is present in query params),
        // we need to go back to the page /souscription/:projectId
        previousStep = 'souscription/' + this.$store.state.tgp.projectId
      }

      return `/${toString(previousStep)}`
    },
  },
  mounted() {
    this.$store.dispatch('steps/setCurrentSection', this.section)
    this.$store.dispatch('steps/setCurrentStep', this.stepName)

    // receive click on next from sticky bottom button
    this.$bus.$on('form-next', () => {
      this.handleSubmitClick()
    })

    // receive click on prev from sticky bottom button
    this.$bus.$on('form-prev', () => {
      this.handlePreviousClick()
    })
  },
  beforeUnmount() {
    // removing eventBus listener
    this.$bus.$off('form-next')
    this.$bus.$off('form-prev')
  },
  methods: {
    ...(mapGetters({
      nextStep: 'steps/nextStep',
      previousStep: 'steps/previousStep',
    }) as {
      nextStep: () => string
      previousStep: () => string
    }),
    handlePreviousClick() {
      this.$emit('previous-click')

      // do not navigate if this prop is true
      if (this.preventPrevious) return

      // track CTA click
      pushCtaClick({ label: 'Précédent' })

      goTo(this.router as unknown as Router, this.previous || '/')
    },
    handleSubmitClick() {
      if (this.disabled) {
        this.validate()
      } else {
        this.submitForm()
      }
    },
    validate() {
      if (!this.v$.$dirty) {
        this.v$.$touch()
      }
    },
    async submitForm() {
      const isFormCorrect = await this.v$.$validate()

      if (!isFormCorrect) return

      this.$emit('submit')

      // to prevent any error to be displayed on the next page,
      // when it using the same component (e.g. for souscription-enfant),
      // the validator is reset
      this.v$.$reset()

      if (this.next) {
        goTo(this.router as unknown as Router, this.next)
      }
    },
  },
})
</script>

<style lang="scss">
.step {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: april-rem($spacing-l);
  margin-top: april-rem($spacing-l);

  @media #{$mq-medium} {
    margin-bottom: april-rem($spacing-2xl);
    margin-top: april-rem($spacing-2xl);
  }

  .step-main {
    align-items: center;
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;

    .step-content {
      display: flex;
      flex: 1;
      flex-direction: column;
      gap: april-rem($spacing-s);
      margin-bottom: april-rem($spacing-xl);
      max-width: april-rem(720);

      @media #{$mq-medium} {
        margin-bottom: april-rem($spacing-2xl);
      }

      &.text {
        text-align: left;

        @media #{$mq-medium} {
          text-align: center;
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          margin: 0;
          white-space: break-spaces;

          strong {
            color: $color-medium;
            font-weight: inherit;
          }
        }

        p {
          color: $color-extrabold-gray;
          margin: 0;
        }
      }
    }

    .step-inputs {
      display: flex;
      flex-direction: column;
      gap: april-rem($spacing-l);
      width: 100%;

      @media #{$mq-small} {
        gap: april-rem($spacing-2xl);
        width: april-rem(350);
      }

      .autocomplete-container .input-content,
      .input-dropdown {
        // HACK: on mobile, ensure last dropdown (with max-height: 40vh;) in form has enough space to be visible
        &.-opened:last-child {
          margin-bottom: max(0px, calc(40vh - april-rem($spacing-2xl)));

          @media #{$mq-small} {
            margin-bottom: 0;
          }
        }
      }

      .highlight-v2-container {
        margin-top: 0;

        &.ui-wrapper {
          width: auto;
        }
      }
    }
  }

  .step-aside {
    align-items: flex-start;
    display: none;
    flex-direction: column;
    width: april-rem(150);

    &:first-child {
      flex: none;
    }

    &:last-child {
      flex-shrink: 1;
    }

    @media #{$mq-medium} {
      display: flex;
    }
  }

  .step-submit {
    display: none;
    justify-content: center;

    @media #{$mq-medium} {
      display: flex;
    }
  }

  #button-next {
    width: april-rem(350);
  }

  .error-message {
    color: $color-medium-red;
    font-size: april-rem($font-size-desktop-xs);
    margin-top: april-rem($spacing-s);
    text-align: left;
  }
}
</style>
